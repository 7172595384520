import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";
import Popup from './Popup';

const Portfolio = () => {

    useEffect(() => {            
        var nav = document.getElementById("navid");

        const navLinkDark = document.querySelectorAll("[id='nav-link-dark']");
    

        if (window.innerWidth < 700) {
            const navLinkDarkArray = [...navLinkDark];
            navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
            }   );
        }   
                
        window.addEventListener("scroll", () => {

            var navimg = document.getElementById("imgid");
            navimg.style.width = '90px';
            navimg.style.height = '90px';
            nav.style.backgroundColor = 'white'; 
            nav.style.transition = '0.5s';
            nav.style.boxShadow = "0 10px 20px 0 rgba(0,0,0,.1)";

            const navLinkDarkArray = [...navLinkDark];
              navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
              });

            if (!window.pageYOffset && window.innerWidth > 700) {
                nav.style.backgroundColor = 'transparent'; 
                nav.style.color = "black";
                nav.style.transition = '0.5s';
                nav.style.boxShadow = "none";
                navimg.style.width = '120px';
                navimg.style.height = '120px';

                const navLinkDarkArray = [...navLinkDark];
                navLinkDarkArray.forEach(id => {
                        id.style.color = 'white';
                });
            }
         });

         var fadeElementsLoad = document.querySelectorAll('.fade-in-up-fast');

         fadeElementsLoad.forEach(function(loadelement) {
     
             loadelement.style.opacity = 1;
             loadelement.style.visibility = 'visible';
             loadelement.style.webkitTransform = 'translateY(0px)';
             loadelement.style.transform = 'translateY(0px)';
         });

       

        window.addEventListener('scroll', function() {

            // Function to check if an element is in the viewport
            function isScrolledIntoView(element) {
                var rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }
        
            // Get all elements with the class 'fade-in-up'
            var fadeElements = document.querySelectorAll('.fade-in-up');
        
            fadeElements.forEach(function(element) {
                var point = "0px", move = "50px";
        
                if (isScrolledIntoView(element)) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(' + point + ')';
                    element.style.transform = 'translateY(' + point + ')';
                } else {
                    element.style.opacity = 0;
                    element.style.visibility = 'hidden';
                    element.style.webkitTransform = 'translateY(' + move + ')';
                    element.style.transform = 'translateY(' + move + ')';
                }
            });

            if (window.innerWidth < 600) {
                fadeElements.forEach(function(element) {
                    element.style.opacity = 1;
                    element.style.visibility = 'visible';
                    element.style.webkitTransform = 'translateY(0px)';
                    element.style.transform = 'translateY(0px)';
                });
            }
        });
        

    }, []);

    return (
        <div id="portfolio-page" className='our-work'>
            <Helmet>
                <title>Our Work</title>
                <meta name="description" 
                      content="All Una Sites are Custom Coded, Fully Responsive, and Completely Unique"
                />
            </Helmet>
                <div className='services-top'>
                    <div id="services-top-img-body" className='my-5'>
                        <img alt="Coding Screen" id='services-img' src='../assets/coding-home.jpg'></img>
                    </div>                
                    <h1 className='services-header-title fade-in-up-fast'>Our Work</h1>
                </div>
                <div className='services-top-wave'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
                </div>
                <div className='portfolio-body'>
                    <h1 className='portfolio-sub-header'>OUR WORK</h1>
                    <h1 className='portfolio-txt'>Meet Some of Our Clients</h1>
                    <div className='portfolio-pics'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='portfolio-pic-container'>
                                <a href='https://oneidacountyhistory.org/' target="_blank">
                                    <img className='portfolio-pic' src='/assets/portfolio/24OCHC.png' />
                                    <div className='portfolio-pic-overlay'>
                                        <h2>Oneida County History Center</h2>
                                        <p>Founded in 1876, the Oneida County History Center collects, preserves and makes accessible the past heritage of Oneida County and the upper Mohawk River Valley.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='portfolio-pic-container'>
                                <a href='https://www.midtownutica.org/' target="_blank">
                                    <img className='portfolio-pic' src='/assets/portfolio/MUCCV2.png' />
                                    <div className='portfolio-pic-overlay'>
                                        <h2>Midtown Utica Community Center</h2>
                                        <p>The Midtown Utica Community Center (MUCC) is an inclusive and multicultural community center in Utica, New York. They are anti-racist, multilingual, and refugee-friendly.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='portfolio-pic-container'>
                                <a href='https://www.greenignite.com/' target="_blank">
                                    <img className='portfolio-pic' src='/assets/portfolio/24GREEN.png' />
                                    <div className='portfolio-pic-overlay'>
                                        <h2>Green Ignite</h2>
                                        <p>The top manufacturers & providers of LED lighting in the Utica-Rome area. Leading the area in the transition towards a more energy-efficient and sustainable future.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='portfolio-pic-container'>
                                <a href='https://utica-yes.com/' target="_blank">
                                    <img className='portfolio-pic' src='/assets/portfolio/24UTICA.png' />
                                    <div className='portfolio-pic-overlay'>
                                        <h2>Utica YES!</h2>
                                        <p>COMING SOON!</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='portfolio-pic-container'>
                                <a href='https://www.ajmrice.com/' target="_blank">
                                    <img className='portfolio-pic' src='/assets/portfolio/24AJ.png' />
                                    <div className='portfolio-pic-overlay'>
                                        <h2>AJ Rice PhD</h2>
                                        <p>AJ Rice PhD is a professor of political science at the University of California, Santa Barbara. His research examines the intersections of capitalism and Black politics in US Cities.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='portfolio-pic-container'>
                                <a href='https://cnydroneservices.com/' target="_blank">
                                    <img className='portfolio-pic' src='/assets/portfolio/24CNY.png' />
                                    <div className='portfolio-pic-overlay'>
                                        <h2>CNY Drone Services</h2>
                                        <p>CNY Drone Services are trusted experts in aerial drone applications across New York State and the Northeast region. With a primary focus on combating invasive pests.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='portfolio-pic-container'>
                                <a href='https://vacunasparatodos.org/' target="_blank">
                                    <img className='portfolio-pic' src='/assets/portfolio/24VACUNAS.png' />
                                    <div className='portfolio-pic-overlay'>
                                        <h2>Vacunas Para Todos</h2>
                                        <p>An organization based in Palm Beach and Martin County, Florida dedicated to informing the Latino community in Spanish about the importance of getting vaccinated.</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='portfolio-pic-container'>
                                {/* <a href=''> */}
                                    <img className='portfolio-pic' src='/assets/portfolio/24BOTS.png' />
                                    <div className='portfolio-pic-overlay'>
                                        <h2>MUCCbots</h2>
                                        <p>MUCCbots is a group of highly motivated refugee students from Utica, NY who work together to create a robot that competes in FIRST Robotics events.</p>
                                    </div>
                                {/* </a> */}
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='portfolio-pic-container'>
                                <a href='https://jewelrybyida.com' target="_blank">
                                    <img className='portfolio-pic' src='/assets/portfolio/IDA.png' />
                                    <div className='portfolio-pic-overlay'>
                                        <h2>Jewelry By Ida</h2>
                                        <p>Jewelry By Ida is a jewelry store specializing in the sale of hand-made custom beaded necklaces, bracelets, and more!</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='portfolio-pic-container'>
                                <a href='https://midtownutica.org/aapi' target="_blank">
                                    <img className='portfolio-pic' src='/assets/portfolio/AAPI.png' />
                                    <div className='portfolio-pic-overlay'>
                                        <h2>Asian American & Pacific Islander Celebration</h2>
                                        <p>
                                        (AAPI) Heritage Month is a major annual celebration of the culture, traditions, and history of Asian Americans and Pacific Islanders in the United States. 
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='portfolio-pic-container'>
                                <a href='https://hmjequityconsulting.com' target="_blank">
                                    <img className='portfolio-pic' src='/assets/portfolio/HMJ.png' />
                                    <div className='portfolio-pic-overlay'>
                                        <h2>HMJ Equity Consulting</h2>
                                        <p>
                                        HMJ Equity Consulting is a social equity consulting and training firm that facilitates embodied and practical learning experiences to empower first-generation leaders.
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='portfolio-pic-container'>
                                <a href='https://justicefornyah.com' target="_blank">
                                    <img className='portfolio-pic' src='/assets/portfolio/nyah.png' />
                                    <div className='portfolio-pic-overlay'>
                                        <h2>Justice for Nyah Mway</h2>
                                        <p>
                                        A community-led space advocating for accountability and justice following the fatal police shooting of 13-year-old Nyah Mway in Utica, NY, providing information on the incident and ways for the community to support.
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
                    </div>
                    <br/><br/><br/><br/><br/><br/><br/>
                    <section id='reviews-bottom-section'>
                    <div>                              
                        <h1 className='why-una'>Let's Get Started on<span> Your Site!</span></h1>
                        <br/>
                        <p> With our unique custom-code approach to web development, Una Web Solutions creates websites that 
                            stick out from the cookie-cutter sites made by website builders. Click below to get a free quote.
                        </p>
                        <div className="portfolio-main-btn py-3">
                            <a href='/contact'><button type="submit" className="main-btn-blue">Get A Free Quote</button></a>
                        </div>
                    </div>
                </section>
            <div id='bottom-wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#161c4c" fillOpacity="1" d="M0,192L60,186.7C120,181,240,171,360,186.7C480,203,600,245,720,229.3C840,213,960,139,1080,128C1200,117,1320,171,1380,197.3L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
            </div>
            <Popup/>
        </div>
    );
}

export default Portfolio